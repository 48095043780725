import React from 'react'
import Svg, {Path, SvgProps, PathProps} from 'react-native-svg'

import {usePalette} from '#/lib/hooks/usePalette'

const ratio = 17 / 64

export function Logotype({
  fill,
  ...rest
}: {fill?: PathProps['fill']} & SvgProps) {
  const pal = usePalette('default')
  // @ts-ignore it's fiiiiine
  const size = parseInt(rest.width || 32)

  return (
    <Svg
      fill="none"
      viewBox="0 0 64 17"
      {...rest}
      width={size}
      height={Number(size) * ratio}>
      <Path
        fill={fill || pal.text.color}
        d="M10.4 7.9 8.3 9.3H1.5L.3 7.9h6.6l.5-.6v-2H0V1.5L1.3 0h8.9L9 1.4H3.6L3 2v2h7.4v3.9zM14.7 9.3l-3.2-2.6V0h10.6v9.3h-7.4zm-.4-7.9v4.8L15.9 8h3.4V1.4h-5zM35 7.9l-1.2 1.4h-9.3V0H35l-1.2 1.4h-6.4V8H35zM44.8 7.9l-1.2 1.4h-7.9V0h2.8v7.9h6.3zM45.9 9.3v-2h1.9v2h-1.9zM49.1 0h2.8v9.3h-2.8V0zM63.4 7.9l-2.1 1.4h-6.9l-1.2-1.4h6.6l.5-.6v-2H53V1.5L54.2 0h8.9L62 1.4h-5.4L56 2v2h7.4v3.9z"
      />
    </Svg>
  )
}
